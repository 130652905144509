import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import {Faq} from '../../models/faq.model';



@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  faqs: Array<Faq> = [];

  constructor() { }

  ngOnInit() {
    const urlBeca = 'https://becajunaeb.pluxee.cl/';
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_sobre-la-beca';
    this.faqs = [
      {
        title: '¿Qué pasos debo seguir para acceder a la app?',
        description: `
          <p>Para acceder a la app Pluxee Chile, debes descargarla a través de tu store o accediendo a este link <strong><a class="inline-link" href="https://onelink.to/vmrd7u" target="_blank" rel="noopener">https://onelink.to/vmrd7u</a></strong></p>
          <p>Regístrate en caso de no tener cuenta o ingresa tu correo electrónico con el cual habilitaste tu cuenta.</p>
           `
      },
      {
        title: '¿Qué pasos debo seguir para enrolarme?',
        description: `
          <p>Para poder registrarte, primero te sugerimos verificar en <strong><a class="inline-link" href="https://baes.junaeb.cl/BAES/servlet/hantecedentes" target="_blank" rel="noopener">https://baes.junaeb.cl/BAES/servlet/hantecedentes</a></strong> que efectivamente seas beneficiario de la BAES.
          Si eres beneficiarios BAES, descarga la app Pluxee Chile o ingresa a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> y crea tu cuenta.</p>
          <ul style="list-style-type: circle">
            <li>Paso 1: Selecciona “¿No tienes una cuenta? Registrarse”</li>
            <li>Paso 2: Ingresa tu dirección de correo electrónico y a continuación, haz clic en el botón Crear mi cuenta.
            Recibirás un correo con un código de verificación de seis dígitos. Este código solo es válido durante cinco minutos. Te recomendamos revisar la bandeja de spam.</li>
            <li>Paso 3: Ingresa el código y presiona el botón Continuar.</li>
            <li>Paso 4: Crea tu contraseña.</li>
            <li>Paso 5: Completa todos los datos de tu perfil. Recibirás un SMS con un código de verificación de seis dígitos, ingrésalo y haz clic en Continuar.</li>
            <li>Paso 6: Valida tu identidad ingresando tu RUT, número de documento y contesta las preguntas de verificación de identidad</li>
          </ul>
          `
  },
      {
        title: '¿Cómo activo la tarjeta?',
        description: `
            <p><strong>Para activar tu tarjeta recuerda que primero debes habilitar tu cuenta, si ya eres un usuario registrado en los portales de Pluxee:</strong></p>
            <ul style="list-style-type: circle">
              <li>Ingresa a tu app Pluxee Chile o a <strong><a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a></strong> con tu correo electrónico y contraseña segura.</li>
              <li>Haz clic en <strong>Activa tu tarjeta</strong> en tu perfil, selecciona <strong>enviar código verificador</strong> (es alfanumérico y de 6 dígitos), revisa tu bandeja de entrada del correo y/o de spam, cópialo y pégalo en tu app Pluxee Chile, y crea tu PIN de 4 dígitos.</li>
              <li>¡Listo! Tu tarjeta y PIN de compra se encuentran activos.</li>
            </ul>
          `
      },
      {
        title: '¿Cómo saber mi saldo?',
        description: `Puedes verlo a través de la app Pluxee Chile, sitio web <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a>
                        o llamando al teléfono gratuito 800 646 155.
            `
      },
      {
        title: '¿Qué es y cómo se usa la Clave Dinámica?',
        description: `
          <p>La clave dinámica es tu instrumento de canje virtual que es clave aleatoria, segura y de un solo uso, que dura máximo 90 minutos. La clave contiene 4 dígitos y permite el pago directo en la caja de nuestros asociados, ¡con ella no necesitas tu tarjeta física!</p>
          <p>Consulta al comercio si opera con este medio de pago.</p>
          <p>Para obtener tu clave dinámica tienes 3 alternativas:</p>
          <ul style="list-style-type: circle">
            <li><strong>Desde tu app Pluxee Chile:</strong> Selecciona la opción <strong>Clave Dinámica</strong> e ingresa tu PIN de compra (contraseña secreta de 4 dígitos que creaste junto con tu cuenta).</li>
            <li><strong>Desde la Web:</strong> Selecciona la opción <strong>Pago</strong> que está en la parte superior derecha, luego <strong>Pago Clave Dinámica</strong>, ingresa tu PIN de compra</li>
            <li><strong>Llamando al número 800 646 155</strong>: Ingresa tu Rut, PIN de compra y selecciona la opción 1.</li>
          </ul>
        `
      },
      {
        title: '¿Qué es y cómo se usa Pago QR?',
        description: `
          <p>Es una forma de pago que te permite comprar en la red comercios, de manera simple, segura y rápida. Para pagar con QR consulta al comercio si opera con este medio de pago o revisa tu buscador de comercios. </p>
          <ul style="list-style-type: circle">
            <li>Ingresa a tu app Pluxee Chile y selecciona la opción <strong>Pago QR</strong>.</li>
            <li><strong>Escanea el código</strong> entregado por el comercio y confirma con tu PIN de compra (contraseña secreta de 4 dígitos que creaste junto a tu cuenta).</li>
          </ul>
          <p>¡Listo! Con Pago QR no necesitas tu tarjeta física.</p>
        `
      },
      {
        title: '¿Dónde encuentro los puntos de canje/comercios asociados?',
        description: `
          <p>Ingresa a nuestro buscador de comercio desde la app Pluxee Chile o el sitio web, o ingresa directamente a través de este <strong><a class="inline-link" href="https://www.pluxee.cl/merchant-finder/es-CL?pluxeeProducts=JUNAEB&merchantTypes=PHYSICAL_AND_ECOM%7CPHYSICAL&_gl=1*17nktx0*_gcl_au*MTM4MDk5ODc3MS4xNzMyNTY4MTYzLjE5MDIxNzA3NzcuMTczODE2MDMxMy4xNzM4MTYwMzEz" target="_blank" rel="noopener">link</a></strong>.</p>

        `
      },
      {
        title: '¿Dónde y cómo uso mi beneficio sin tarjeta?',
        description: `<p>Puedes usar tu beneficio sin tener la tarjeta en tus manos y sin contacto:</p>
          <ul style="list-style-type: circle">
            <li>En nuestra red de comercios, dictando tu <strong>Rut y Clave Dinámica</strong> (los comercios que tienen la máquina donde antiguamente se deslizaba tu tarjeta, pueden ingresarlo ahí).
            Puedes verificar en tu app Pluxee Chile, sección Comercios nuestra red de <strong>comercios</strong> disponibles y las formas de pago habilitadas en cada uno de los comercios).</li>
            <li>Usando <strong>Pago QR</strong>, en comercios habilitados presenciales.</li>
          </ul>
            `
      },
      {
        //9
        title: '¿Puedo solicitar tarjeta física para hacer uso de mi beneficio?',
        description: `
          <p>Solicítala ingresando a la web presionando la opción “Solicitar tarjeta física”. Recibirás tu tarjeta en tu IES o casa de estudio un plazo máximo de 12 días hábiles. Actívala desde la App o web y confirma tu PIN de compra.</p>

        `
      },
      {
        //10
        title: '¿Puedo seguir utilizando la aplicación después de pedir la tarjeta física?',
        description: `
            <p>Si, puedes seguir utilizando la app Pluxee Chile. </p>
            <p>Para generar clave dinámica (clave que te permite realizar un pago), recuerda que tienes 2 opciones:</p>
            <p>Offline: A través de nuestro número gratuito 800 646 155 ingresando tu RUT y PIN de compra y selecciona opción 1 Generación de clave dinámica.</p>
            <p>Con internet: A través la plataforma web <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> opción “Pago” seleccionando “Clave dinámica” ingresando PIN de compra. </p>
            <p>Una vez generada tu clave por cualquiera de las opciones, esta tiene una duración de 90 minutos</p>
          `
      },
      {
        //11
        title: '¿Es necesario tener plan de datos (internet) en el celular para pagar con la aplicación?',
        description: `
          <p>Para generar clave dinámica (clave que te permite realizar un pago), no es necesario contar con internet, de hecho, tienes 2 opciones: </p>
          <p>Offline: A través de nuestro número gratuito 800 646 155 ingresando tu RUT y PIN de compra y selecciona opción 1 Generación de clave dinámica.</p>
          <p>Con internet: A través de la app Pluxee Chile en opción “Clave dinámica” ingresando tu PIN de compra o en la plataforma web <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> opción “Pago” seleccionando “Clave dinámica” ingresando PIN de compra. </p>
          <p>Una vez generada tu clave por cualquiera de las opciones, esta tiene una duración de 90 minutos y puede ser utilizada sin conexión a internet</p>
        `
      },
      {
        //12
        title: '¿Dónde puedo revisar la información de mis últimas compras?',
        description: `
          <p>Puedes verlo a través de la app Pluxee Chile, sitio web o llamando al teléfono gratuito 800 646 155, opción 4. </p>

        `
      },
      {
        //13
        title: 'Tengo cuenta y no puedo iniciar sesión en la aplicación, ¿qué hago?',
        description: `
          <p>Recuerda tener siempre la última actualización de la app Pluxee Chile instalada y revisar que estes ingresando la contraseña correcta, si has olvidado la contraseña, haz clic en el botón “¿Olvidaste tu contraseña?”</p>
          <p>Si necesitas ayuda, contáctate con nuestros canales de atención</p>
          <ul style="list-style-type: circle">
            <li>Atención telefónica gratuita 800 646 155. Horario de Lunes a Domingo, de 8 AM a 8 PM</li>
            <li>Chatbot: <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> disponible 24/7</li>
            <li>App Pluxee Chile: Formulario de Contacto</li>
          </ul>

        `
      },
      {
        //14
        title: 'No tengo cuenta y no puedo iniciar sesión en la aplicación, ¿qué hago?',
        description: `
          <p>Contáctate con nuestros canales de atención</p>
         <ul style="list-style-type: circle">
            <li>Chatbot: <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> disponible 24/7</li>
            <li>Correo electrónico: junaeb.cl@pluxeegroup.cl</li>
          </ul>
`
      },
      {
        //15
        title: '¿Qué es y cómo se usa el Pin de Compra?',
        description: `
    <p>Es una clave de 4 dígitos que utilizas al momento de comprar con tu tarjeta en nuestros comercios asociados, también es la clave que ingresas al momento de solicitar clave dinámica.</p>
  `
      },
      {
        //16
        title: '¿Qué pasa si olvido el Pin de Compra?',
        description: `
    <p>De no recordar tu PIN de compras, puedes realizar el cambio seleccionando desde la app la opción <strong>cambiar PIN de compra</strong>, posterior a eso te enviaremos un código a tu correo para que ingreses en la app y tengas la opción de crear un nuevo PIN de compra, el cual debe cumplir con los siguientes requisitos:</p>
    <ul style="list-style-type: circle">
      <li>Debe ser dígitos no consecutivos.</li>
      <li>Puedes repetir un dígito un máximo de 3 veces.</li>
      <li>Tu PIN no puede corresponder a una porción del RUT.</li>
    </ul>
  `
      },
      {
        //17
        title: '¿Qué pasa si necesito enrolarme y extravié mi cédula?',
        description: `
    <p>Debes contactarte con nosotros a través de la siguiente casilla: junaeb.cl@pluxeegroup.com, enviando la siguiente información:</p>
    <ul style="list-style-type: circle">
      <li>Rut</li>
      <li>Solicitud de la nueva cédula entregada por el registro civil</li>
    </ul>
    <p>De esta manera validaremos si eres beneficiario y te podremos entregar los pasos a seguir.</p>
  `
      },
      {
        //18
        title: 'Realicé cambio legal de mis datos personales ¿Qué debo hacer para actualizar mis datos? ',
        description: `
          <p>Ingresa a la app Pluxee Chile, sección “Configurar”, “Perfil” y “Editar” en la web sección “Mis datos”</p>
        `
      },
      {
        //19
        title: '¿Cuáles son los canales de contacto?',
        description: `
    <p>Nuestros canales habilitados son:</p>
    <ul style="list-style-type: circle">
      <li>Atención telefónica gratuita 800 646 155. Horario de Lunes a Domingo, de 8 AM a 8 PM</li>
      <li><strong>Chatbot</strong>: <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> disponible 24/7</li>
      <li><strong>App Pluxee Chile</strong>: Formulario de Contacto en tu perfil</li>
    </ul>
  `
      },
      {
        //20
        title: '¿Qué hacer en caso de registrar compras que yo no realicé?',
        description: `
          <ul style="list-style-type: circle">
            <li>Si ves una compra que tú no realizaste, bloquea inmediatamente tu tarjeta en app Pluxee Chile o la página web <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a>
             y luego realiza una denuncia en <a class="inline-link" href="https://casos.pluxee.cl/" target="_blank" rel="noopener">https://casos.pluxee.cl/</a>. </li>
          </ul>
        `
      },
      {
        //21
        title: '¿Qué debo hacer en caso de problemas con el pago? (descuento en tarjeta, pero la compra no pasó)',
        description: `
          <p>Realiza una solicitud en la zona de ayuda de la app Pluxee Chile o web, seleccionando la opción de denuncia, opción funcionamiento app, problema en la transacción en el formulario de contacto. Para acreditar el problema debes adjuntar archivo de respaldo de la situación.</p>

        `
      },

      {
        //22
        title: 'Extravié/robaron mi tarjeta, ¿Qué debo hacer?',
        description: `
    <p>Bloquéala ingresando a nuestra App Pluxee Chile, el sitio web <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> o llamando al 800 646 155 opción bloquear tarjeta.</p>

  `
      },
      {
        //23
        title: '¿Hay restricciones para la descarga de app u otras, con algunos equipos móviles?',
        description: `
    <p>Los requerimientos mínimos son: Android 7.0 o iOS versión 15.</p>
    <p>En dispositivos Huawei, debes instalar la APP “AURORA” y en dicha app buscar Pluxee Chile. Para más información puedes dirigirte al siguiente link: <a class="inline-link" href="https://consumer.huawei.com/es/community/details/Aurora-Store-La-alternativa-Open-Source-a-Google-Play/topicId_40116/" target="_blank" rel="noopener">Guía de instalación Aurora Store</a></p>
  `
      },
      {
        //24
        title: '¿Cómo postulo a la BAES?',
        description: `
    <p>Es una beca que se asigna o renueva a estudiantes (según corresponda el caso), que cumpla con los requisitos establecidos y siempre que exista disponibilidad presupuestaria.</p>
    <p>Para más información, ingresa a <a class="inline-link" href="https://www.junaeb.cl/beca-alimentacion-la-educacion-superior" target="_blank" rel="noopener">https://www.junaeb.cl/beca-alimentacion-la-educacion-superior</a></p>

  `
      },
      {
        //25
        title: '¿Cómo saber cuál es el proveedor de mi BAES?',
        description: `
    <p>Durante el año 2025 – 2026 la Beca BAES proveerá sus servicios de Comercios asociados a través de;</p>
    <ul style="list-style-type: circle">
      <li>Pluxee, en la Región Metropolitana.</li>
    </ul>
  `
      },
      {
        //26
        title: '¿Cuándo es el aumento de la BAES 2025?',
        description: `
        <p>El aumento del saldo será de carácter progresivo.</p>
        <p>Región de Magallanes: Marzo a junio $49.000</p>
        <p>Julio a diciembre $50.000</p>
        <p>Resto del país: Marzo a junio $47.000</p>
        <p>Julio a diciembre $48.000 </p>
        `
      },
      {
        //27
        title: '¿Cuál es el valor del servicio para los platos Junaeb (valor bandeja) para este año 2025',
        description: `
    <p>Para el año 2025 el valor del menú Preferencial BAES, de no puede superar los $ 2.450 en la Región de Magallanes y $ 2.350 en el resto de las regiones del país.</p>
    <p>Los comercios además pueden contar con un “Menú Mejorado”, en donde su precio puede llegar hasta los $ 4.900 en la región de Magallanes, y $ 4.700 en el resto de las regiones. </p>
        `
      },
      {
        //28
        title: '¿Cuándo se cargará el monto mensual de mi BAES y cuál es su vigencia?',
        description: `
        <p>Los saldos se liberan el 1° de cada mes (con excepción de marzo) y vencen los 5 del mes siguiente (se incluyen sábados, domingos y festivos.)</p>
        <p>Para el mes de marzo los saldos se liberarán de la siguiente forma:</p>
        <ul style="list-style-type: circle">
          <li>07 de marzo para alumnos renovantes</li>
          <li>17 de marzo para alumnos nuevos y renovantes rezagados</li>
</ul>
        `
      },
      {
        //29
        title: 'No me cargaron mi saldo, ¿cuáles pueden ser los motivos?',
        description: `
        <p>Para poder dar una respuesta certera debemos contar con más información, por favor ingresa a <a class="inline-link" href="https://www.junaeb.cl/beca-alimentacion-la-educacion-superior" target="_blank" rel="noopener">https://www.junaeb.cl/beca-alimentacion-la-educacion-superior</a> y escribe a Atención de Usuarios, indicando nombre, Rut, y si eres renovante BAES, o nueva asignación. </p>
      `
      },
      {
        //30
        title: '¿Cuáles son las restricciones de compra con mi BAES?',
        description: `
        <p>En términos generales, los productos que no se pueden comprar con la BAES son:</p>
        <ul style="list-style-type: circle">
          <li>Bebidas alcohólicas.</li>
          <li>Cigarrillos</li>
          <li>Fármacos</li>
          <li>Productos que no pertenezcan a la categoría Alimentos.</li>
        </ul>
         <p>Sumado a esto, para la BAES, existen distintas restricciones, dependiendo del tipo de comercio asociado.</p>
         <ul style="list-style-type: circle">
          <li><strong>En supermercados:</strong> Podrás comprar solo alimentos con hasta 2 sellos de advertencia "Alto en" de la ley 20.606.</li>
          <li><strong>Cadenas de comida:</strong> Sólo podrás comprar los menús saludables aprobados por Junaeb y publicados en la sala de ventas.</li>
        </ul>
      `
      },
      {
        //31
        title: '¿Qué hacer en caso de que un comercio asociado efectúe un cobro indebido, mal trato, abuso u otro contra un beneficiario BAES?',
        description: `
        <p>Para denunciar a un comercio asociado a la Red de Pluxee, tienes dos opciones:</a>
        <ul style="list-style-type: circle">
          <li>Ingresa a tu app Pluxee Chile y en tu perfil sección Contáctanos envíanos tu denuncia.</li>
          <li>Dirígete al <strong>Centro de Ayuda</strong> en <a class="inline-link" href="${urlBeca}" target="_blank" rel="noopener">${urlBeca}</a> y escribe tu reclamo.</li>
        </ul>
`
      },
      {
        //32
        title: '¿Habrá BAES Estival este verano 2025?',
        description: `
        <p>Debido a razones presupuestarias, durante el período enero y febrero 2025 no se implementará la BAES en la modalidad de práctica estival.</p>
        `
      },
      {
        //33
        title: '¿Habrá BAES Estival este verano 2026?',
        description: `
        <p>Cualquier información sobre el beneficio de BAES Estival (práctica) se avisará a través de los canales oficiales de Junaeb.</p>
        `
      }

    ];
  }

}
