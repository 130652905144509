import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { CurrentCard } from '../../../dto/CurrentCard';
import { PrivateService } from '../../../service/private.service';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { toAscii } from '../../../security/helpers/custom.validator';
import { Movements } from '../../../dto/Movements';
import { CardHeader } from '../../../dto/CardHeader';
import {ActivatedRoute, Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { PublicService } from 'src/app/service/public.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { AuthGuard } from '../../../guards/auth/auth.guard';

defineLocale('es', esLocale);

declare var loadingButton: any;

@Component({
  selector: 'app-private-my-card',
  templateUrl: './private-my-card.component.html',
  styleUrls: ['./private-my-card.component.css']
})
export class PrivateMyCardComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  seguimientoUrl: string = environment.seguimientoSolicitudUrlClickOh;
  cambioPinUrl: string = '';
  editPin: string = environment.connect.editPin;
  currentUser: any;
  statusCard: string;
  loadingMovements = false;
  private sub: any;
  error = '';
  disabledPagoRut = '';
  pan = '';
  displayBlock = '';
  displayIssued = '';
  cardHeader: CardHeader;
  movements: Movements[];

  loadingHeader = true;
  header = false;

  /*TARJETA*/
  currentCard: CurrentCard;
  personIcon: string;
  cardIcon: string;
  cardCheck: string;

  /*Visual*/
  movimientos = false;
  seguimiento = false;

  /*Formularios*/
  blockedForm: FormGroup;
  submittedBlocked = true;
  movementForm: FormGroup;
  submittedMovement = true;

  checkDatePicker = true;
  locale = 'es';

  failEmail = false;

  titleError: string;
  messageError: string;

  hasta: any;
  desde: any;

  visiblePagoRut: string;

  activateCard: string;

  visiblePagoRut2: string;

  flagRequestPhysicalCard = false;
  flagIssuedPhysicalCard = false;

  constructor(
               private privateService: PrivateService,
               private publicService: PublicService,
               private route: ActivatedRoute,
               private formBuilder: FormBuilder,
               private cd: ChangeDetectorRef,
               private readonly router: Router,
               private authService: AuthGuard,
               private _localeService: BsLocaleService,
               private reCaptchaV3Service: ReCaptchaV3Service) {

    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
    this.cambioPinUrl =  this.generateUrlChangePin(this.currentCard.pan);
    // @ts-ignore
    this.activateCard = 'Activar tarjeta';
    this._localeService.use(this.locale);

    this.blockedForm = this.formBuilder.group({
      motivoBloqueo: new FormControl('', [Validators.required]),
      // tslint:disable-next-line:max-line-length
    });

    this.movementForm = this.formBuilder.group({
      desde: new FormControl(''),
      hasta: new FormControl('')
    });

    // tslint:disable-next-line:triple-equals
    if (this.currentUser.infoUser.paymentRutActivated == true) {
      this.visiblePagoRut = 'OK';
    }


  }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_mi-tarjeta';
    this.getCurrentCardStatus();
    if(this.currentUser.infoUser.paymentRutActivated == true){
      this.visiblePagoRut2 = 'Activo'
    }else{
      this.visiblePagoRut2 =null;
    }
    this.getBeneficiaryInfo();
  }

  activarPagoRut() {

    this.disabledPagoRut = 'pagoRut';

    // tslint:disable-next-line:triple-equals
    if (this.currentUser.infoUser.paymentRutActivated == false) {

      this.privateService.paymentRut().subscribe((data: any) => {
        // tslint:disable-next-line:triple-equals
        if (data.code == '01' && data.message == 'APROBADA') {
          this.visiblePagoRut = 'OK';
          // tslint:disable-next-line:max-line-length
          Swal.fire('Pago RUT activado', 'Paga dictando tu RUT e ingresando tu pin de compra en el comercio asociado. Contarás con pago con RUT habilitado hasta el día ' + data.expirationDate, 'success');

          // tslint:disable-next-line:triple-equals no-unused-expression
          const kittensFromLocalStorage = JSON.parse(localStorage.getItem('currentLoggedUser'));
          kittensFromLocalStorage.infoUser.paymentRutActivated = true;
          kittensFromLocalStorage.infoUser.expirationDate = data.expirationDate;

          localStorage.setItem('currentLoggedUser', JSON.stringify(kittensFromLocalStorage));
          this.currentUser.infoUser.expirationDate = data.expirationDate;


          // tslint:disable-next-line:triple-equals

            this.visiblePagoRut2 = 'Activo'

        } else {
          if(data.titleError == 'Pago Rut'){
            // tslint:disable-next-line:comment-format
            Swal.fire(data.titleError, data.messageError, 'success');
            this.visiblePagoRut = 'OK';

          }else if(data.titleError == 'Pago RUT no activado') {
            // tslint:disable-next-line:comment-format
            Swal.fire(data.titleError, data.messageError, 'success');
          }
        }

      }, error => {
        this.errorMessage(error);
        Swal.fire('Pago RUT no activado', 'No pudimos completar la activación de Pago RUT, te agradecemos probar más tarde', 'success');
      });

    } else {
      this.visiblePagoRut = 'OK';
    }
  }
  mostrarMovimiento(estado: boolean) {

    const finPan = this.pan.substr(10);
    this.pan = '**********' + finPan;
    const pipe = new DatePipe('en-US');
    if (!this.compareDates()) {
      this.submittedMovement = false;
      return;
    }

    if (this.movementForm.invalid) {
      this.submittedMovement = false;
      return;
    }

    if (estado) {
      this.checkDatePicker = true;
      this.loadingMovements = true;
      this.movimientos = false;
      const now = new Date();

      if (this.hasta === undefined) { this.hasta = pipe.transform(now, 'dd-MM-yyyy'); }
      if (this.desde === undefined) { this.desde = pipe.transform(now.setDate(now.getDate() - 30), 'dd-MM-yyyy'); }

      try {
        // tslint:disable-next-line:triple-equals
        if (this.movementForm.controls.desde.value != '' && this.movementForm.controls.desde.value != this.desde) {
          this.desde = pipe.transform(this.movementForm.controls.desde.value, 'dd-MM-yyyy');
        }
      } catch (error) {}
      try {
        // tslint:disable-next-line:triple-equals
        if (this.movementForm.controls.hasta.value != '' && this.movementForm.controls.hasta.value != this.hasta) {
        this.hasta = pipe.transform(this.movementForm.controls.hasta.value, 'dd-MM-yyyy');
        }
      } catch (error) {}

      this.movementForm.controls.desde.setValue(this.desde);
      this.movementForm.controls.hasta.setValue(this.hasta);

          this.privateService.movements(this.desde, this.hasta).subscribe((data: any) => {
            // tslint:disable-next-line:triple-equals
            if (data.idResponse == 0 ) {
              // @ts-ignore
              this.movements = [];
            } else {
              this.movements = data;
            }

            this.movimientos = estado;
            this.loadingMovements = false;
            this.cd.detectChanges();
        }, error => {
          this.errorMessage(error);
        });

      this.cd.detectChanges();
    }
  }

  getCardHeader() {
    this.cardHeader = new CardHeader();
    if (localStorage.getItem('cardHeader') != null) {
      this.cardHeader = JSON.parse(localStorage.getItem('cardHeader'));
      this.loadingHeader = false;
      this.header = true;
      this.cd.detectChanges();
    } else {
        this.privateService.cardHeader(this.currentCard.pan).subscribe((data: any) => {
          this.cardHeader = data;
          localStorage.setItem('cardHeader', JSON.stringify(this.cardHeader));
          this.loadingHeader = false;
          this.header = true;
          this.cd.detectChanges();
          }, error => {
            this.errorMessage(error);
          });

    }
  }

  getBeneficiaryInfo() {
    this.flagRequestPhysicalCard = false;
    this.privateService.getBeneficiaryInfo().subscribe((data: any) => {
      if (data.response) {
        const changeStatusDate = new Date(data.data.changeStatusDate);
        const daysDiff = Math.floor((Date.now() - changeStatusDate.getTime()) / (1000 * 60 * 60 * 24));
        if (data.data.tarjetaSolicitada === 'S') {
          this.flagRequestPhysicalCard = true;
          if (daysDiff < 30) {
            this.flagIssuedPhysicalCard = true;
          }
        }
      }
    }, error => {
      this.errorMessage(error);
    });
  }


  getCurrentCardStatus() {
    this.currentCard = new CurrentCard();
    if (localStorage.getItem('currentCard') != null) {
      if (this.currentCard.pan === undefined) {
          this.privateService.currentCard().subscribe((data: any) => {
            this.currentCard = data;
            localStorage.setItem('currentCard', JSON.stringify(this.currentCard));
            this.getCardHeader();
            this.cd.detectChanges();
            this.statusCardDisplay();
          }, error => {
            this.errorMessage(error);
          });

      } else {
        this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
        this.getCardHeader();
        this.cd.detectChanges();
        this.statusCardDisplay();
      }
    } else {
      this.reCaptchaV3Service.execute(environment.captcha.siteKey, 'currentCard', (token) => {
        this.privateService.currentCard().subscribe((data: any) => {
          this.currentCard = data;
          localStorage.setItem('currentCard', JSON.stringify(this.currentCard));
          this.getCardHeader();
          this.cd.detectChanges();
          this.statusCardDisplay();
        }, error => {
          this.errorMessage(error);
        });
      });
    }
  }

  onConfirmLockClic(result) {
    this.flagRequestPhysicalCard = result;
    this.getCurrentCardStatus();
  }

  statusCardDisplay() {
    this.pan = toAscii(this.currentCard.pan);
    const finPan = this.pan.substr(10);
    this.pan = '**********' + finPan;
    switch (this.currentCard.statusCardId) {
      case 1: {
        this.statusCard = 'active';
        this.personIcon = 'state-person--active';
        this.cardIcon = 'state-card__icon--active';
        this.cardCheck = 'fa-check';
        this.seguimiento = false;

        break;
      }
      case 4: {
        // tslint:disable-next-line:no-shadowed-variable
        const finPan = this.pan.substr(10);
        this.pan = '**********' + finPan;
        this.statusCard = 'issued';
        this.displayIssued = 'issued_step_one';
        this.personIcon = 'state-person--issued';
        this.cardIcon = 'state-card__icon--issued';
        this.cardCheck = 'fa-arrow-right';
        this.seguimiento = true;

        break;
      }
      default: {
        // tslint:disable-next-line:no-shadowed-variable
        const finPan = this.pan.substr(10);
        this.pan = '**********' + finPan;
        this.displayBlock = 'block_step_two';
        this.personIcon = 'state-person--block';
        this.cardIcon = 'state-card__icon--block';
        this.cardCheck = 'fa-lock';
        this.movimientos = false;
        this.seguimiento = true;
        this.statusCard = 'block';
        break;
      }
    }

    this.cd.detectChanges();
    this.sub = this.route.params.subscribe(params => {
      const bloque = params['bloque'];
      // tslint:disable-next-line:triple-equals
      if (bloque == 'bloquear') {
        this.mostrarStep1Bloqueo();
      }
      // tslint:disable-next-line:triple-equals
      if (bloque == 'ver-movimientos') {
        this.mostrarMovimiento(true);
      }

      // tslint:disable-next-line:triple-equals
      if (bloque == 'pago-rut') {
        this.visiblePagoRut = 'OK';
         this.activarPagoRut();
      }
      // tslint:disable-next-line:triple-equals
      if (bloque == 'activar') {
        this.mostrarStep1Activar();
      }
    }, error => {
      this.errorMessage(error);
    });
    this.cd.detectChanges();

  }

compareDates() {
  // tslint:disable-next-line:triple-equals
    if (this.movementForm.controls.hasta.value == '' && this.movementForm.controls.desde.value != '') {
      this.movementForm.controls.hasta.setErrors({'notValid': true});
      return false;
    }
  // tslint:disable-next-line:triple-equals
    if (this.movementForm.controls.desde.value == '' && this.movementForm.controls.hasta.value != '') {
      this.movementForm.controls.desde.setErrors({'notValid': true});
      return false;
    }

  // tslint:disable-next-line:triple-equals
    if (this.movementForm.controls.desde.value != '' && this.movementForm.controls.hasta.value != '') {
      const desde = new Date(this.movementForm.controls.desde.value);
      const hasta = new Date(this.movementForm.controls.hasta.value);

      if (hasta < desde) {
        this.movementForm.controls.hasta.setErrors({'notValid': true});
        return false;
      }
    }
    this.movementForm.controls.desde.setErrors(null);
    this.movementForm.controls.hasta.setErrors(null);
    return true;
  }


  mostrarStep1Bloqueo() {
    this.movimientos = false;
    this.statusCard = 'block';
    this.displayBlock = 'block_step_one';
    this.cd.detectChanges();
  }

  mostrarStep1Activar() {
    this.statusCard = 'issued';
    this.displayIssued = 'issued_step_two';
    this.cd.detectChanges();
  }

  mostrarStep2Bloqueo() {

    if (this.blockedForm.invalid) {
      this.submittedBlocked = false;
      this.cd.detectChanges();
      return;
    }
    // tslint:disable-next-line:no-unused-expression
    new loadingButton('btn-lock_card');
    this.error = '';
      // tslint:disable-next-line:no-shadowed-variable
      this.privateService.changeStatusAndLockCardBeneficiary(
        // tslint:disable-next-line:no-shadowed-variable
      this.blockedForm.controls.motivoBloqueo.value).subscribe((data: any) => {
        // tslint:disable-next-line:no-shadowed-variable
          this.privateService.currentCard().subscribe((data: any) => {
            this.currentCard = data;
            const finPan = this.pan.substr(10);
            this.pan = '**********' + finPan;
            localStorage.setItem('currentCard', JSON.stringify(this.currentCard));
            this.statusCard = 'block';
            this.displayBlock = 'block_step_two';
            this.personIcon = 'state-person--block';
            this.cardIcon = 'state-card__icon--block';
            this.cardCheck = 'fa-lock';
            this.movimientos = false;
            this.seguimiento = true;
            this.cd.detectChanges();
          }, error => {
            this.errorMessage(error);
          });

      }, error => {
        this.error = error.messageError;
        this.cd.detectChanges();
        return this.error;
      });




  }

  enableCard() {
    window.open(this.generateUrlChangePin(this.currentCard.pan));
}

  errorCase(data) {
    if (data.titleError && data.messageError) {
      this.failEmail = true;
      this.header = false;
      this.titleError = data.titleError;
      this.messageError = data.messageError;
    }
  }


  reload() {
    this.error = '';
    this.failEmail = false;
    this.header = true;
    this.titleError = '';
    this.messageError = '';
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.failEmail = true;
      this.header = false;
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }

  generateUrlChangePin(currentCard: String): string {
    let url = new URL(environment.connect.changePin);
    this.pan = toAscii(this.currentCard.pan);
    url.searchParams.append("card", "1" + this.pan.substring(8));
    url.searchParams.append("pin", "edit");

    return url.href;
  }
}
