import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FooterComponent } from '../shared/footer/footer.component';
import { Articles } from '../dto/Articles';
import { HelpCenterComponent } from '../components/help-center/help-center.component';
import { HttpClientModule } from '@angular/common/http';
import { PublicService } from '../service/public.service';
import { Banners } from '../dto/Banners';
import { HeaderComponent } from '../shared/footer/header/header.component';
import { AppModule } from '../app.module';
import { environment } from '../../environments/environment';
import { Observable, forkJoin } from 'rxjs';

declare var startArticles: any;
declare var startBanner: any;
declare var showModal: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  banners: Array<Banners> = [];
  articles: Array<Articles> = [{}] as Array<Articles>;
  contentPrintBanner: boolean = false;
  @ViewChild(HeaderComponent, { static: true }) headerComponent: HeaderComponent;
  titleError: string;
  messageError: string;
  public show = true;
  merchantSearcher: string;
  fichaAntecedentes: string;
  contentPrintArticles: boolean = false;
  bannerLayoutPreload: boolean = true;

  numberWords = ['one', 'two', 'three', 'four'];

  constructor(private publicService: PublicService,
    private cdRef: ChangeDetectorRef) {
    this.merchantSearcher = environment.tradeSearchEngine;
    this.fichaAntecedentes = environment.hantecedentes;
  }

  ngAfterViewInit(): void {
    this.getBanner();

    if (sessionStorage.getItem('sessionExpire') != null && sessionStorage.getItem('sessionExpire') === 'true') {
      sessionStorage.removeItem('sessionExpire');

      new showModal;
      this.cdRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.headerComponent.menuActive = 'm_inicio';
  }

  getBanner(): void {
      this.publicService.banner().subscribe((data: Array<Banners>) => {
        data.reverse().forEach((local: Banners) => {
          this.banners.push(local);
        });

    }, error => {
      this.errorMessage(error);
    });
  }

  functionStartArticles(): void {
    this.contentPrintArticles = true;
    new startArticles();
    this.cdRef.detectChanges();
  }

  functionStartBanner(): void {
    this.contentPrintBanner = true;
    new startBanner();
    this.cdRef.detectChanges();
  }

  errorMessage(error: { titleError: string; messageError: string; }): void {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }
}
